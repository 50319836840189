import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import IListMixin from "@/mixins/interface/IListMixin";
import UrlService from "@/services/UrlService";

import AppConfig from "@/config/AppConfig";

const NAMESPACE_TABLE = "table";

@Component
export default class ListMixin extends Vue implements IListMixin {
  public name = "ListMixin";

  protected urlService: UrlService = new UrlService();

  @Mutation("SET_FILTERS", { namespace: NAMESPACE_TABLE }) setFilters: any;
  @Mutation("SET_DRAGGING_ROW", { namespace: NAMESPACE_TABLE })
  setDraggingRow: any;
  @Mutation("SET_DRAGGING_ROW_INDEX", { namespace: NAMESPACE_TABLE })
  setDraggingRowIndex: any;
  @Mutation("SET_CURRENT_PAGE", { namespace: NAMESPACE_TABLE }) setPage: any;
  @Mutation("SET_ITEMS_PER_PAGE", { namespace: NAMESPACE_TABLE })
  setItemsPerPage: any;
  @Mutation("SET_SORTS", { namespace: NAMESPACE_TABLE }) setSorts: any;

  @Getter("getCurrentPage", { namespace: NAMESPACE_TABLE }) getPage: any;
  @Getter("getDraggingRow", { namespace: NAMESPACE_TABLE }) getDraggingRow: any;
  @Getter("getDraggingRowIndex", { namespace: NAMESPACE_TABLE })
  getDraggingRowIndex?: number | null;
  @Getter("getFilters", { namespace: NAMESPACE_TABLE }) getFilters: any;
  @Getter("getItemsPerPage", { namespace: NAMESPACE_TABLE })
  getItemsPerPage: any;
  @Getter("getSorts", { namespace: NAMESPACE_TABLE }) getSorts!: Array<any>;
  @Getter("getTotal", { namespace: NAMESPACE_TABLE }) getTotal: any;
  @Getter("getTableParams", { namespace: NAMESPACE_TABLE }) getTableParams: any;

  // Buefy Table Settings
  public isTableBordered = false;
  public buttonWidth = 65; // px
  public isTableStriped = false;
  public isTableNarrowed = false;
  public isTableHoverable = true;
  public isTableFocusable = false;
  public isTableBackendPagination = true;
  public isTablePaginated = true;
  public isTableSortMultiple = true;
  public isTableBackendSorting = true;
  public tableSortMultipleKey = null;
  public isTableBackendFiltering = true;
  public tableDebounceSearchMillis = 1000;
  public isTableDraggable = false;
  public isTableCheckable = false;
  public isTableDetailed = false;
  public tableDetailKey = "id";
  public tableDetailTransition = "fade";
  public showDetailIcon = true;
  public selectedRow = null;

  protected checkedRows: Array<any> = [];

  protected created(): void {
    const tableOptionsFromRouteQuery: any = this.urlService.parseQuery(
      this.$route.query ?? {}
    );

    if (tableOptionsFromRouteQuery.filter) {
      this.filters = tableOptionsFromRouteQuery.filter;
    }

    if (tableOptionsFromRouteQuery.sort) {
      // TODO fix sorting by url stuff
      // this.sorts = tableOptionsFromRouteQuery.sort;
    }

    this.registerListener();
    this.init();
    this.onReloadTable();
  }

  protected get draggingRow(): any {
    return this.getDraggingRow;
  }

  protected set draggingRow(draggingRow: any) {
    this.setDraggingRow(draggingRow);
  }

  protected get draggingRowIndex(): number | null {
    return this.getDraggingRow;
  }

  protected set draggingRowIndex(draggingRowIndex: number | null) {
    this.setDraggingRowIndex(draggingRowIndex);
  }

  protected get filters(): any {
    return this.getFilters;
  }

  protected set filters(filters: any) {
    // removed console_log
    this.setFilters(filters);
  }

  protected get itemsPerPage(): number {
    return this.getItemsPerPage;
  }

  protected set itemsPerPage(itemsPerPage: number) {
    this.setItemsPerPage(itemsPerPage);
  }

  protected get page(): number {
    return this.getPage;
  }

  protected set page(page: number) {
    this.setPage(page);
  }

  protected get sorts(): Array<any> {
    return this.getSorts;
  }

  protected set sorts(sorts: Array<any>) {
    this.setSorts(sorts);
  }

  protected get total(): number {
    return this.getTotal;
  }

  public addFilter(column: string, value: any) {
    if (this.filters[column]) {
      delete this.filters[column];
    }
    const filters = this.filters;
    filters[column] = value;
    this.filters = filters;
  }

  public removeFilter(column: string): boolean {
    if (this.filters[column]) {
      delete this.filters[column];
      return true;
    }
    return false;
  }

  // Dummy functions and getters cause we can't use abstract as not supported by vue property decorator
  // This functions should be overwritten in child class
  protected get resource(): string {
    console.warn(
      "This resource from ListMixin. Implement method in child class"
    );
    return "";
  }

  protected init(): void {
    // removed console_log
    console.warn(
      "This is init from ListMixin. implement method in child class"
    );
  }

  protected loadList(params: any): any {
    // removed console_log
    console.warn(
      "This is loadList from ListMixin. implement method in child class"
    );
  }

  protected loadListItems(params: any, force = false) {
    this.loadList({
      resource: this.resource,
      params: params,
      force_reload: force,
    });
  }

  protected resetFilters(): void {
    this.filters = {};
    this.onFiltersChanged(this.filters);
  }

  protected registerListener(): void {
    /*
    new OnCrudRemovedSuccess().listen((event: any) => {
      this.onReloadTable();
    });

     */
  }

  // Hooks
  protected onReloadTable(force = false) {
    const params = {
      ...this.getTableParams,
    };
    this.loadListItems(params, force);
  }

  /**
   * Filter hook. Fired if filters changed after debounce time if set
   * @param filters
   * @private
   */
  protected onFiltersChanged(filters: any) {
    this.filters = filters;

    this.urlService.addParamsToLocation(
      filters,
      1,
      this.itemsPerPage ?? 25,
      this.sorts ?? {}
    );

    this.onReloadTable(true);
  }

  protected onPerPageChanged(itemsPerPage: any) {
    console.debug("ListMixin.onPerPagechanged", itemsPerPage);
    this.itemsPerPage = itemsPerPage;
    this.page = 1;

    this.onReloadTable(true);
  }

  protected onPageChanged(page: number) {
    this.page = page;
    this.urlService.addParamsToLocation(
      this.filters ?? {},
      page,
      this.itemsPerPage ?? 25,
      this.sorts ?? {}
    );
    this.onReloadTable(true);
  }

  // Sort hooks
  protected onSortChange(field: any, order: any, event: any) {
    const existingPriority = this.sorts.filter((i) => i.field === field)[0];

    if (existingPriority) {
      existingPriority.order =
        existingPriority.order === "desc" ? "asc" : "desc";
    } else {
      // request sorted data from backend
      this.sorts.push({ field, order });
    }

    this.urlService.addParamsToLocation(
      this.filters ?? {},
      1,
      this.itemsPerPage ?? 25,
      this.sorts ?? {}
    );

    this.onReloadTable(true);
  }

  protected onSortingPriorityRemoved(field: any) {
    this.sorts = this.sorts.filter((priority) => priority.field !== field);
    //    this.sorts = [];
    // removed console_log
    this.onReloadTable();
  }

  // Table Drag and Drop Hooks
  protected onDropColumn(e: any) {}

  protected dragstart(payload: any) {
    this.draggingRow = payload.row;
    this.draggingRowIndex = payload.index;
    payload.event.dataTransfer.effectAllowed = "copy";
  }

  protected dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = "copy";
    payload.event.target.closest("tr").classList.add("is-selected");
    payload.event.preventDefault();
  }

  protected dragleave(payload: any) {
    payload.event.target.closest("tr").classList.remove("is-selected");
    payload.event.preventDefault();
  }

  protected onDropRow(payload: any): any {
    // let fromRow: any = this.draggingRow;
    //  let toRow: any = this.usersChannelList ? this.usersChannelList[payload.index] : {};

    payload.event.target.closest("tr").classList.remove("is-selected");
  }

  protected get appConfig(): any {
    return AppConfig;
  }
}
