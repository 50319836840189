
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ReloadButton extends Vue {
  protected name = "ReloadButton";

  @Prop({ required: false, default: false })
  public loading!: boolean;
}
