import AppConfig from "@/config/AppConfig";
// @ts-ignore
import { ITableOptions } from "@/interfaces/ITableOptions";

export default class UrlService {
  // Write to URL
  // ------------------

  public addParamsToLocation(
    filters: any = {},
    currentPage = 1,
    itemsPerPage = -1,
    sort: any = {}
  ): void {
    const url =
      "?" + this.paramsToUrl(filters, currentPage, itemsPerPage, sort);
    history.replaceState({}, "", url);
  }

  public sortParamsToQueryString(sortParams: Array<any>) {
    let sortQueryParam = "";
    for (const sortParam of sortParams) {
      sortQueryParam += `order[${sortParam.field}]=${sortParam.order}` + "&";
    }

    return sortQueryParam;
  }

  private paramsToUrl(
    filters: any = {},
    currentPage = 1,
    itemsPerPage = -1,
    sort: any = {}
  ): string {
    if (itemsPerPage === -1) {
      itemsPerPage = AppConfig.DEFAULT_TABLE_RESULTS_PER_PAGE;
    }

    const urlArray: any = [];

    Object.keys(filters).forEach((filter) => {
      urlArray.push(filter + "=" + encodeURIComponent(filters[filter]));
    });

    if (sort !== {}) {
      urlArray.push(this.sortParamsToQueryString(sort));
    }

    urlArray.push("page=" + String(currentPage));

    urlArray.push("itemsPerPage=" + String(itemsPerPage));

    return urlArray.join("&");
  }

  // Read from URL
  // ---------------------
  public parseQuery(routeQuery: any): ITableOptions {
    // Parse filters from url query
    const tableOptions: any = {};

    if (Object.keys(this.parseFiltersFromQuery(routeQuery)).length > 0) {
      tableOptions.filter = this.parseFiltersFromQuery(routeQuery);
    }

    // Add itemsPerPage and current page if available from query
    if (routeQuery && routeQuery.page) {
      tableOptions.page = Number(routeQuery.page);
    }

    if (routeQuery && routeQuery.itemsPerPage) {
      tableOptions.itemsPerPage = Number(routeQuery.itemsPerPage);
    }

    // Parse sort params from query and set to tableOptions
    const sort: any = this.parseSortParamsFromQuery(routeQuery);

    if (sort !== {}) {
      tableOptions.sort = sort;
    }

    return tableOptions;
  }

  protected parseSortParamsFromQuery(routeQuery: any): any {
    const regex = /order\[(.+?)]/gm;
    //const str = `order[name]`;

    const orderParams: Array<any> = [];

    Object.keys(routeQuery).forEach((queryParamKey: string) => {
      const matches = regex.exec(queryParamKey);
      if (matches) {
        let type: string = routeQuery[queryParamKey];

        if (Array.isArray(type)) {
          type = type[0];
        }

        const params: any = {
          field: matches[1],
          type: type,
        };

        orderParams.push(params);
      }
    });

    return orderParams;
  }

  protected parseFiltersFromQuery(routeQuery: any): any {
    const filters: any = {};

    if (routeQuery) {
      const queries: any = { ...routeQuery };

      Object.keys(queries).forEach((queryKey: string) => {
        if (
          !this.isQueryKeyOrderParam(queryKey) &&
          queryKey !== "page" &&
          queryKey !== "itemsPerPage" &&
          queryKey !== ""
        ) {
          filters[queryKey] = queries[queryKey];
        }
      });
    }
    return filters;
  }

  protected isQueryKeyOrderParam(queryKey: string): boolean {
    const regex = /order\[(.+?)]/gm;

    const matches = regex.exec(queryKey);

    return !!matches;
  }
}
