
import { Component, Prop, Vue } from "vue-property-decorator";
import ReloadButton from "@/components/buttons/ReloadButton.vue";

@Component({
  components: { ReloadButton },
})
export default class DefaultTableLayout extends Vue {
  protected name = "DefaultTableLayout";

  @Prop({ required: false, default: false })
  public loading!: any;
}
